import { Button } from "antd";
import styled from "styled-components";

export const StyledSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  @media (max-width: 570px) {
    flex-direction: column;
  }
`;

export const StyledSelectButton = styled(Button)`
  color: ${(props) => props.theme.main};

  &:hover {
    border: 1.5px solid ${(props) => props.theme.main};
    color: ${(props) => props.theme.main};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.main};
    color: ${(props) => props.theme.main};
  }
`;

export const StyledSelectValue = styled.p`
  margin: 0;
  padding: 0.5rem 1rem;
`;
