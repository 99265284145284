import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledPdfPreview } from "./styled";
import Loader from '../Loader'
import { isString } from "lodash";

function PdfPreview({ value, part }) {
  const { canvasId, resultId } = value || {}
  const tempOk = useSelector(state => state.canvas?.temp?.summary?.resultId)

  const dispatch = useDispatch()
  const url = `${process.env.REACT_APP_BACKEND_BASE_URI}/api/downloadsummarypdf?id=${resultId}&idCanvas=${canvasId}&part=${part}`

  useEffect(() => {
    dispatch({ type: "DOWNLOAD_PDF_SUMMARY", payload: { part } })
  }, [part])

  return tempOk && isString(canvasId) && resultId ? (
    <StyledPdfPreview>
      <iframe
        id="pdfpreview"
        frameborder="0"
        width={"100%"}
        height={500}
        title="Summary PDF"
        src={url}
      >
      </iframe>
    </StyledPdfPreview>
  ) : <Loader />

}

export default PdfPreview;
