import React from "react";
import { useSelector } from "react-redux";
import { getCategoryName } from "../../containers/canvas/selector";
import { StyledCategoryName } from "./styled";

function CategoryName({ value }) {
  const categoriesNameMap = useSelector(getCategoryName(value));
  return <StyledCategoryName>{categoriesNameMap}</StyledCategoryName>;
}

export default CategoryName;
