export const theme = {
  main: {
    default: "#FDFDFD",
    main: "#990A2C",
    second: "#fdfdfd",
    mainDark: "#8C082C",
    white: "#FDFDFD",
    buttonColor: {
      disabled: "#222",
      primary: "#fdfdfd",
      secondary: "#990A2C",
    },
    buttonBgColor: {
      disabled: "#333",
      primary: "#990A2C",
      secondary: "#fdfdfd",
    },
  },
  blu: {
    main: "#0A2197",
    second: "#fdfdfd",
    mainDark: "#03045e",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#0A2197",
    },
    buttonBgColor: {
      primary: "#0A2197",
      secondary: "#fdfdfd",
    },
  },
  orange: {
    main: "#EA652B",
    second: "#fdfdfd",
    mainDark: "#AA3706",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#EA652B",
    },
    buttonBgColor: {
      primary: "#EA652B",
      secondary: "#fdfdfd",
    },
  },
  green: {
    main: "#2B5B55",
    second: "#FDFDFD",
    mainDark: "#1B3834",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#2B5B55",
    },
    buttonBgColor: {
      primary: "#2B5B55",
      secondary: "#fdfdfd",
    },
  },
  purple: {
    main: "#4A17B4",
    second: "#FDFDFD",
    mainDark: "#2E008E",
    white: "#FDFDFD",
    buttonColor: {
      primary: "#fdfdfd",
      secondary: "#4A17B4",
    },
    buttonBgColor: {
      primary: "#4A17B4",
      secondary: "#fdfdfd",
    },
  },
};
