import React, { useEffect } from "react";
import Flow from "@stackhouseos/flower-client";
import flow from "./canvas.flower.json";
import { ThemeProvider } from "styled-components";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentCanvas } from "./selector";
import Loader from "../../components/Loader";
import { theme } from "../../theme";

export default function Canvas() {
  const currentCanvas = useSelector(getCurrentCanvas);

  return (
    <ThemeProvider theme={theme[currentCanvas?.theme] || theme.main}>
      <Header />
      <Flow
        name={flow.name}
        elements={flow.elements}
        reducerName="canvas.current"
      />
    </ThemeProvider>
  );
}
