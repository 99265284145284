import React from "react";
import { SidebarCircle } from "..";
import SidebarIcon from "../SidebarIcon";

import {
  StyledSidebar,
  StyledSidebarTitle,
  StyledSidebarSubTitle,
} from "./styled";

function Sidebar({
  title,
  subtitle,
  icon,
  children,
  sizeMinWidth,
  sizeMaxWidth,
}) {
  const contentDefault = (
    <SidebarCircle>
      <SidebarIcon icon={icon} />
      <StyledSidebarTitle>{title}</StyledSidebarTitle>
      {subtitle && <StyledSidebarSubTitle>{subtitle}</StyledSidebarSubTitle>}
    </SidebarCircle>
  );

  return (
    <StyledSidebar sizeMinWidth={sizeMinWidth} sizeMaxWidth={sizeMaxWidth}>
      {children || contentDefault}
    </StyledSidebar>
  );
}

export default Sidebar;
