import { all } from "redux-saga/effects";
import { flowerSaga } from "@stackhouseos/flower-client";
import Canvas from "../containers/canvas/saga";
import homeSaga from "../containers/auth/saga";

export default function* root() {
  const sagas = [Canvas(), flowerSaga(), homeSaga()];

  yield all(sagas);
}
