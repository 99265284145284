import Loader from "../../../../components/Loader";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Logout = React.memo(({ flowName, reducerName }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "LOGOUT",
      payload: {
        flowName,
        reducerName,
      },
    });
  }, []);

  return <Loader />;
});

export default Logout;
