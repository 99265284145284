import React from "react";
import { StyledDynamicList, StyledIndex, StyledListItem } from "./styled.js";
import ScrollspyNav from "react-scrollspy-nav";
import { uniqBy } from "lodash";

const Item = React.memo(({ prefix, model, renderChildren }) => {
  return renderChildren(model, prefix);
});

const DefaultComponent = ({ children }) => <>{children}</>;

export default function DynamicList({
  value = [],
  renderChildren,
  item_container_children,
  childId,
  enableSpy,
  sectionKey = "category",
}) {
  const Component = enableSpy ? ScrollspyNav : DefaultComponent;

  const uniqValue = uniqBy(
    Object.values(value).map((e) => ({
      _id: e[sectionKey],
      name: `section_${e[sectionKey]}`,
    })),
    "name"
  );

  const filteredCategoriesOnly = uniqValue.filter((e) => e._id !== "");

  return (
    <Component
      scrollTargetIds={filteredCategoriesOnly.map((e) => e.name)}
      activeNavClass="is-active"
    >
      <StyledDynamicList>
        {filteredCategoriesOnly.map((rec, i) => {
          return (
            <div key={i}>
              <StyledListItem key={i}>
                <StyledIndex key={i}>{i + 1}</StyledIndex>

                <Item
                  key={rec._id}
                  prefix={`${childId.replace("steps", "categories")}.${
                    rec._id
                  }`}
                  renderChildren={renderChildren}
                  model={item_container_children}
                />
              </StyledListItem>
            </div>
          );
        })}
      </StyledDynamicList>
    </Component>
  );
}
