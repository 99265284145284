import styled from "styled-components";

export const StyledSidebarIcon = styled.span`
  color: "${(props) => props.theme.white}";
  font-size: 10rem;
  padding-bottom: 0.5rem;
  margin: 0;
  z-index: 2;

  @media (max-width: 900px) {
    display: none;
  }
`;
