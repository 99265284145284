import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

//value
const initialState = {
  instructionModal: false,
  researchModal: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.hideTutorial = true;
      state.instructionModal = !state.instructionModal;

    },
    toggleModalResearch: (state) => {
      state.hideTutorial = true;
      state.researchModal = !state.researchModal;
    },
  },
  extraReducers: {
    "@flower/app/update": (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = appSlice.actions;

export default appSlice.reducer;
