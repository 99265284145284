import {
  BookOutlined,
  TrophyOutlined,
  UpCircleOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  RocketOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
  UserOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons/lib/icons";
import React from "react";
import { StyledSidebarIcon } from "./styled";

const icons = {
  UpCircleOutlined: <UpCircleOutlined />,
  TrophyOutlined: <TrophyOutlined />,
  BookOutlined: <BookOutlined />,
  InfoCircleOutlined: <InfoCircleOutlined />,
  MessageOutlined: <MessageOutlined />,
  CheckCircleOutlined: <CheckCircleOutlined />,
  RocketOutlined: <RocketOutlined />,
  CloseCircleOutlined: <CloseCircleOutlined />,
  UserAddOutlined: <UserAddOutlined />,
  UserOutlined: <UserOutlined />,
  StopOutlined: <StopOutlined />,
  WarningOutlined: <WarningOutlined />,
  Globe: <img src={"../imageSample/globe.svg"} alt="globe img" />,
};

export default function SidebarIcon({ value, icon }) {
  return <StyledSidebarIcon>{icons[icon || value]}</StyledSidebarIcon>;
}
