import { createSelector } from "reselect";

const selectGlobalSlice = (state) => state.app;

export const selectInstructionModal = createSelector(
  selectGlobalSlice,
  (app) => app.instructionModal
);

export const selectResearchModal = createSelector(
  selectGlobalSlice,
  (app) => app.researchModal
);
