import React, { useLayoutEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { get } from "lodash";

import { StyledBarChart } from "./styled";

function BarChart({ config = {}, data = {}, chartId }) {
  useLayoutEffect(() => {
    am4core.options.autoSetClassName = true;
    const chart = am4core.create(chartId, am4charts.XYChart);
    chart.data = data;
    chart.config = config;
    chart.interactionsEnabled = false;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    valueAxis.numberFormatter.numberFormat = "#.";
    valueAxis.maxPrecision = 0;
    valueAxis.min = 0;
    valueAxis.max = 10;
    categoryAxis.dataFields.category = "keyword";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    categoryAxis.renderer.labels.template.events.on("over", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "soft");
    });

    categoryAxis.renderer.labels.template.events.on("out", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "none");
    });

    // Configure axis label
    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.wrap = true;
    label.maxWidth = 120;
    label.tooltipText = "{keyword}";

    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    valueAxis.tooltip.disabled = true;

    chart.colors.list = [am4core.color("#F27329"), am4core.color("#B92626")];

    am4core.addLicense("ch-custom-attribution");

    // Create series
    for (const row of config?.rows || []) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = row;
      series.dataFields.categoryX = "keyword";
      series.name = row
        .replace(/#|_/g, "")
        .replace("  ", " " + get(data, [0, row]) + " ");
    }
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose();
    };
  }, [chartId, config, data]);

  return (
    <StyledBarChart id={chartId} style={{ width: "100%", height: "500px" }} />
  );
}

export default BarChart;
