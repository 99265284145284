import React from "react";
import { StyledSummaryItem, StyledSummaryItemtext } from "./styled";

export default function SummaryItem({ title, childId }) {
  const [, , , , id] = childId.split(".");
  return (
    <StyledSummaryItem href={`#section_${id}`}>
      <StyledSummaryItemtext>{title}</StyledSummaryItemtext>
    </StyledSummaryItem>
  );
}
