import React, { useCallback } from "react";
import {
  StyledCompanyName,
  StyledHeader,
  StyledLink,
  StyledLogoContainer,
  StyledNavLinks,
  StyledRightHeader,
  StyledLogoImg,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../containers/app/reducer";
import { getCurrentCanvas, getHistory } from "../../containers/canvas/selector";
import { getIsLogged } from "../../containers/auth/selector";
import { themeLogo } from "./logoMap";

function Header() {
  const currentCanvas = useSelector(getCurrentCanvas);
  const history = useSelector(getHistory);
  const isLogged = useSelector(getIsLogged);

  const dispatch = useDispatch();

  const open = useCallback(() => {
    dispatch(actions.toggleModal());
  }, [dispatch]);

  const openResearch = useCallback(() => {
    dispatch(actions.toggleModalResearch());
  }, [dispatch]);

  const logout = useCallback(() => {
    dispatch({ type: "LOGOUT" });
  }, [dispatch]);

  return (
    <StyledHeader>
      <StyledRightHeader>
        <StyledLogoContainer>
          <StyledLogoImg
            src={themeLogo[currentCanvas?.theme] || themeLogo.main}
            alt="logo"
          />
        </StyledLogoContainer>
        {/*   <StyledCompanyName>
          <StyledText
            $variant="danger"
            fontWeight="bold"
            align="left"
            fontSize="small"
          >
            Fondazione Mondo digitaleLogin
          </StyledText>
        </StyledCompanyName> */}
      </StyledRightHeader>
      <StyledNavLinks>
        {currentCanvas && history && history.length > 4 && (
          <StyledLink onClick={open}>{"Istruzioni"}</StyledLink>
        )}
        <StyledLink onClick={openResearch}>{"Approfondimento"}</StyledLink>
        {isLogged && <StyledLink onClick={logout}>{"Logout"}</StyledLink>}
      </StyledNavLinks>
    </StyledHeader>
  );
}

export default Header;
