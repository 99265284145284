import styled from "styled-components";

export const StyledLogoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 4rem 0;
  overflow-x
`;
