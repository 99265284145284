import React, { useContext, useEffect } from 'react'
import { FlowerContext } from '@stackhouseos/flower-client'
import { useDispatch } from 'react-redux'


const ParentGoToNext = React.memo(() => {
  const dispatch = useDispatch()
  const { parentFlow, reducerName } = useContext(FlowerContext)

  useEffect(() => {
    dispatch({
      type: 'flower/next',
      payload: {
        flowName: parentFlow,
        reducerName
      }
    })
  }, [dispatch, reducerName, parentFlow])

  return null
})

export default ParentGoToNext