import styled from "styled-components";
import styledMap from "styled-map";

const SidebarMaxWidth = styledMap("sizeMaxWidth", {
  small: "15%",
  medium: "20%",
  big: "40%",
});

const SidebarMinWidth = styledMap("sizeMinWidth", {
  small: "20%",
  medium: "25%",
  big: "37%",
});

export const StyledFooter = styled.div`
  background-color: rgba(253, 253, 253, 0.92);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 4rem;
  bottom: 0;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;
