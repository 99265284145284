import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 500px) {
    flex-direction: ${({ responsiveRowDirection }) =>
      responsiveRowDirection ? "row" : "column"};
    align-items: center;
  }
`;
