import { Select } from "antd";
import styled from "styled-components";

export const StyledKeyTag = styled(Select)`
  flex: 1;
  min-height: 100%;
  border: 1px solid white;
  z-index: 2;

  &.ant-select-selector {
    border: 1px solid #970a2a;
  }

  .ant-select-selection-item {
    border: none;
  }
`;

export const StyledEditIcon = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;
