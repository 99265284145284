import styled from "styled-components";
import { Button } from "antd";
import { mapToTheme as theme } from "styled-map";

const StyledButton = styled(Button)`
  border-radius: 10px;
  background-color: ${theme("buttonBgColor", "$variant")};
  color: ${theme("buttonColor", "$variant")};
  border: 1px solid ${theme("buttonColor", "$variant")};
  max-width: 40rem;
  min-width: 5rem;
  z-index: 1;
  font-weight: 700;

  &:hover {
    background-color: ${theme("buttonBgColor", "$variant")};
    color: ${theme("buttonColor", "$variant")};
    border: 1px solid ${theme("buttonColor", "$variant")};
    opacity: 0.6;
  }
  &:focus {
    color: ${theme("buttonColor", "$variant")};
    border-color: ${theme("buttonColor", "$variant")};
  }
`;

export { StyledButton };
