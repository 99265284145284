import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons/lib/icons";
import React from "react";
import { StyledButton } from "./styled";

const icons = {
  arrowRight: <ArrowRightOutlined />,
  arrowLeft: <ArrowLeftOutlined />,
};

export default function LinkTo({
  title,
  url = '/',
  variant,
  icon,
  paddingSpace,
  disabled,
  size='default',
}) {

  return (
    <>
      <a href={url}>
        <StyledButton
          //onClick={() => linkTo(url)}
          disabled={disabled}
          $variant={variant}
          icon={icons[icon]}
          $paddingSpace={paddingSpace}
          size={size}
        >
          {title}
        </StyledButton>
      </a>
    </>
  );
}
