import logoMain from "./logos/logo.png";
import logoBlu from "./logos/logoBlu.png";
import logoOrange from "./logos/logoOrange.png";
import logoGreen from "./logos/logoGreen.png";
import logoPurple from "./logos/logoPurple.png";

export const themeLogo = {
  main: logoMain,
  blu: logoBlu,
  orange: logoOrange,
  green: logoGreen,
  logoPurple: logoPurple,
};
