import React from "react";
import ReactDOM from 'react-dom/client'
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Flower, { Devtools } from "@stackhouseos/flower-client";
import store from "./store";
import App from "./App";
import * as components from "./components";
import "./index.css";

Flower.registerComponents({ ...components });

// Devtools({ port: 8770 });

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)
