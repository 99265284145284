import styled from "styled-components";

export const StyledSinglePageContent = styled.div`
  background-color: ${(props) => props.theme.white};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 6rem ${({ $gutterHorizontal }) => ($gutterHorizontal ? "2rem" : "0")}
    5rem;
  overflow-x: hidden;

  @media (max-width: 900px) {
    padding: 2rem
      ${({ $gutterHorizontal }) => ($gutterHorizontal ? "1rem" : "0")};
  }
`;
