import styled from "styled-components";
import { BarChart, LineChart, RadarChart } from "recharts";

export const MainChartsContainer = styled.div`
  padding: 2rem 0;
  width: 100%;
  height: 550px;
  margin-bottom: 4rem;
`;

export const StyledSwitchArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const StyledBarChart = styled.div`
  padding: 2rem 0;
  margin-bottom: -5rem;

  .amcharts-AmChartsLogo {
    display: none;
  }

  @media (max-width: 1200px) {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

export const StyledLineChart = styled(LineChart)`
  padding: 2rem 0;
  width: 100%;

  .amcharts-AmChartsLogo {
    display: none;
  }

  @media (max-width: 1200px) {
    padding: 0;
  }
`;

export const StyledRadarChart = styled.div`
  padding: 1rem;
  width: 100%;

  .amcharts-AmChartsLogo {
    display: none;
  }
`;

export const StyledChartContainer = styled.div`
  width 100%;
`;
