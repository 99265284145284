import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import IntroFlow from "../../containers/instructions";
import { selectInstructionModal } from "../../containers/app/selector";
import { StyledCloseIcon, StyledModal, StyledModalContainer } from "./styled";
import { actions } from "../../containers/app/reducer";

function InstructionModal() {
  const instructionModal = useSelector(selectInstructionModal);
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    dispatch(actions.toggleModal());
  }, [dispatch]);

  return (
    <StyledModalContainer show={instructionModal} onCancel={handleCancel}>
      <StyledModal>
        <StyledCloseIcon
          onClick={handleCancel}
          style={{
            fontSize: "18px",
            border: "2px solid",
            borderRadius: "100%",
            padding: "1rem",
            backgroundColor: "#FDFDFD",
            color: "black",
          }}
        />
        {instructionModal && <IntroFlow />}
      </StyledModal>
    </StyledModalContainer>
  );
}

export default InstructionModal;
