import Flow from "@stackhouseos/flower-client";
import Loader from "../../../../components/Loader";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Signup = React.memo(({ flowName, reducerName, email, password }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SIGNUP",
      payload: {
        flowName,
        reducerName,
        email,
        password,
      },
    });
  }, []);

  return (
    <Flow style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Loader />
    </Flow>
  );
});

export default Signup;
