import * as Realm from "realm-web";
import { takeLatest, put, select } from "redux-saga/effects";
import { actionUpdate } from "@stackhouseos/flower-client";
import { updateNodeData } from "@stackhouseos/flower-client";
import app from "../../utils/api";
import { getParameterByName } from "../../utils/url";

function* signup({ payload }) {
  const { flowName } = payload;

  const signup = yield select((state) => state.auth);
  const { email, password } = signup || {};

  yield put(updateNodeData("auth", "error", undefined));
  yield put(updateNodeData("auth", "success", undefined));

  try {
    const lowerEmail = email.toString().toLowerCase();

    yield app.emailPasswordAuth.registerUser(lowerEmail, password);

    const credentials = Realm.Credentials.emailPassword(lowerEmail, password);
    const user = yield app.logIn(credentials);

    const customUserData = yield user.refreshCustomData();

    yield put(actionUpdate("auth", "isLogged", true));
    yield put(
      actionUpdate("auth", "user", {
        ...customUserData,
        id: user.id,
        email: lowerEmail,
      })
    );

    yield put(updateNodeData("auth", "success", true));
  } catch (error) {
    yield put(actionUpdate("auth", "isLogged", false));

    if (error.message.indexOf("password must") > -1) {
      yield put(updateNodeData("auth", "error", "Password must be"));
    }

    if (error.message.indexOf("name already") > -1) {
      yield put(updateNodeData("auth", "error", "Name already in use"));
    }

    yield put(updateNodeData("auth", "error", error.message));
    yield put(updateNodeData("auth", "success", false));
  } finally {
    yield put(actionUpdate("auth", "password", undefined));
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* login({ payload }) {
  const { flowName, email, password } = payload;

  const lowerEmail = email.toString().toLowerCase();

  yield put(updateNodeData("auth", "error", null));

  try {
    const credentials = Realm.Credentials.emailPassword(lowerEmail, password);
    const user = yield app.logIn(credentials);
    console.log("Login ok");

    const customUserData = yield user.refreshCustomData();

    yield put(
      actionUpdate("auth", "user", {
        ...customUserData,
        id: user.id,
        email: lowerEmail,
      })
    );

    yield put(actionUpdate("auth", "isLogged", true));
    yield put(updateNodeData("auth", "success", true));
  } catch (error) {
    console.log("TCL: function*login -> error", error);
    yield put(actionUpdate("auth", "isLogged", false));
    yield put(updateNodeData("auth", "error", "error"));
  } finally {
    yield put(actionUpdate("auth", "password", undefined));
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* forgotPassword({ payload }) {
  const { flowName } = payload;

  const forgotPass = yield select((state) => state.auth);
  const { email } = forgotPass || {};
  const lowerEmail = email.toString().toLowerCase();

  yield put(updateNodeData("auth", "error", undefined));
  yield put(updateNodeData("auth", "success", undefined));

  try {
    yield app.emailPasswordAuth.sendResetPasswordEmail(lowerEmail);
  } catch (error) {
    console.log("TCL: function*login -> error", error);
    yield put(updateNodeData("auth", "error", error.message));
  } finally {
    yield put(actionUpdate("auth", "password", undefined));
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* resetPassword({ payload }) {
  const { flowName } = payload;

  const resetPass = yield select((state) => state.auth);
  const { newPassword } = resetPass || {};

  const params = new URLSearchParams(window.location.search)
  const token = params.get("token")
  const tokenId = params.get("tokenId")

  yield put(updateNodeData(flowName, "error", undefined));
  yield put(updateNodeData(flowName, "success", undefined));

  try {
    yield app.emailPasswordAuth.resetPassword({
      password: newPassword,
      token,
      tokenId
    })
  } catch (error) {
    console.log("TCL: function*login -> error", error.message);
    yield put(updateNodeData(flowName, "error", error.message));
  } finally {
    yield put(actionUpdate(flowName, "password", undefined));
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

function* logout() {
  try {
    yield put(actionUpdate("auth", "isLogged", false));
    yield put(actionUpdate("auth", "password", null));

    app.currentUser.logOut();
    yield window.scrollTo(0, 0);
  } catch (error) {
    //
  } finally {
    window.location.href = "/";
  }
}


function* signupMoodle({ payload }) {
  const { flowName } = payload;

  yield put(updateNodeData("auth", "error", undefined));
  yield put(updateNodeData("auth", "success", undefined));
  const jwt = getParameterByName('moodle_jwt')

  try {

    if(!jwt){
      throw new Error('missing-jwt')
    }

    const credentials = Realm.Credentials.function({ jwt });
    const user = yield app.logIn(credentials);
    console.log("Login moodle ok");

    const customUserData = yield user.refreshCustomData();

    yield put(
      actionUpdate("auth", "user", {
        ...customUserData,
        id: user.id,
      })
    );

    yield put(actionUpdate("auth", "isLogged", true));
    yield put(updateNodeData("auth", "success", true));

  } catch (error) {

    console.log("TCL: function*login -> error", error);

    yield put(actionUpdate("auth", "isLogged", false));
    yield put(updateNodeData("auth", "error", "error"));

  } finally {
    yield put({ type: "flower/next", payload: { flowName } });
  }
}

export default function* homeSaga() {
  yield takeLatest("LOGIN", login);
  yield takeLatest("LOGOUT", logout);
  yield takeLatest("SIGNUP", signup);
  yield takeLatest("SIGNUP_MOODLE", signupMoodle);
  yield takeLatest("FORGOT_PASSWORD", forgotPassword);
  yield takeLatest("RESET_PASSWORD", resetPassword);
}
