import styled from "styled-components";

const StyledSummaryItem = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid transparent;
  opacity: 0.65;
  word-break: break-word;

  &.is-active {
    opacity: 1;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledSummaryItemIndex = styled.h3`
  color: ${(props) => props.theme.second};
  border: 1px solid ${(props) => props.theme.second};
  text-align: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-bottom: 18px;
  font-size: 1.2rem;
`;

const StyledSummaryItemtext = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.second};
`;

export { StyledSummaryItem, StyledSummaryItemIndex, StyledSummaryItemtext };
