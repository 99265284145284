import Flower from "@stackhouseos/flower-client";
import flow from "./research.flower.json";

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectResearchModal } from "../app/selector";
import { StyledCloseIcon, StyledModal, StyledModalContainer } from "./styled";
import { actions } from "../app/reducer";

function ResearchModal() {
  const researchModal = useSelector(selectResearchModal);
  const dispatch = useDispatch();

  const handleCancel = useCallback(() => {
    dispatch(actions.toggleModalResearch());
  }, [dispatch]);

  return (
    <StyledModalContainer show={researchModal} onCancel={handleCancel}>
      <StyledModal>
        <StyledCloseIcon
          onClick={handleCancel}
          style={{
            fontSize: "18px",
            border: "2px solid",
            borderRadius: "100%",
            padding: "1rem",
            backgroundColor: "#FDFDFD",
            color: "black",
          }}
        />
        <Flower name={flow.name} elements={flow.elements} reducerName="research" />
      </StyledModal>
    </StyledModalContainer>
  );
}

export default ResearchModal;
