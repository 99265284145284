import React from "react";
import { StyledPageTitle, StyledPageTitleText } from "./styled";
import parse from "html-react-parser";

export default function PageTitle({ title, value }) {
  return (
    <StyledPageTitle>
      <StyledPageTitleText>{parse(title || value)}</StyledPageTitleText>
    </StyledPageTitle>
  );
}
