import styled from "styled-components";

export const StyledSwitchButton = styled.div`
  margin: 0 1rem;
  border-bottom: 5px solid ${(props) => props.theme.main};
  width: 5rem;
  text-align: center;
  cursor: pointer;
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  font-weight: 700;

  &:hover {
    background-color: rgba(98, 98, 98, 0.1);
  }
`;

export const StyledSwitchText = styled.p`
  margin: 0.5rem;

  text-transform: uppercase;
`;
