import React, { useMemo } from "react";
import Table from "../Table/table";

function TableChart({ value, onChange, config, prefix }) {
  const { columnName, rows = [], numberOfColumns = 1 } = config || {};

  const initialData = useMemo(() => {
    const altrianniObj = rows.map(anno => anno.indexOf('###') > -1 ? ({ text: anno, type: 'selectYear' }) : anno)
    return [
      [columnName, ...altrianniObj],
      ...(Array(parseInt(numberOfColumns))
        .fill("")
        .map((r) => [
          { type: "autoComplete" },
          ...rows.map((r) => ({ type: "number" })),
        ])
      )
    ];
  }, [columnName, rows, numberOfColumns]);

  if (!config) return null;

  return (
    <div>
      <Table
        initialData={initialData}
        config={config}
        onChange={onChange}
        values={value}
        prefixTable={prefix}
      />
    </div>
  );
}

export default TableChart;
