import styled from "styled-components";

export const StyledSpacer = styled.div`
  height: 2px;
  margin: 2rem 0;

  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.main};

  @media (max-width: 1200px) {
    margin: 2rem 0 1rem;
  }
`;
