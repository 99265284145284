import React from "react";
import { Sidebar } from "..";
import { StyledPage } from "./styled";

function Page({
  title,
  icon,
  subtitle,
  children,
  sidebar_container,
  sizeMaxWidth,
  sizeMinWidth,
  dynamicTitle
}) {
  return (
    <StyledPage>
      <Sidebar
        title={dynamicTitle || title}
        subtitle={subtitle}
        icon={icon}
        sizeMaxWidth={sizeMaxWidth}
        sizeMinWidth={sizeMinWidth}
      >
        {sidebar_container}
      </Sidebar>
      {children}
    </StyledPage>
  );
}

export default Page;
