import React from "react";
import { StyledSidebarItem, StyledSidebarItemText } from "./styled";

export default function SidebarItem({ text }) {
  return (
    <StyledSidebarItem>
      <StyledSidebarItemText>{text}</StyledSidebarItemText>
    </StyledSidebarItem>
  );
}
