import React from "react";
import { StyledText } from "../Text/styled";
import { StyledDivider, StyledDividerContainer } from "./styled";

export default function Divider({
  testo,
  fontWeight,
  fontSize,
  variant,
  paddingSpace,
}) {
  return (
    <StyledDividerContainer>
      <StyledDivider />
      <StyledText
        fontWeight={fontWeight}
        fontSize={fontSize}
        $paddingSpace={paddingSpace}
        variant={variant}
        style={{ textTransform: "uppercase" }}
      >
        {testo}
      </StyledText>
      <StyledDivider />
    </StyledDividerContainer>
  );
}
