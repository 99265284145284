import React from "react";
import { StyledTextArea } from "./styled";

export default function MyTextArea({
  placeholder,
  value,
  onChange,
  variant,
  fontWeight,
}) {
  return (
    <StyledTextArea
      $variant={variant}
      fontWeight={fontWeight}
      placeholder={placeholder}
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      rows="7"
    />
  );
}
