import React from "react";
import { StyledLogoContainer } from "./styled";
//"https://mondodigitale.org/files/u1/logo.png"

function LogoContainer({ value }) {
  return value ? (
    <StyledLogoContainer>
      <img src={value} alt="logo" />
    </StyledLogoContainer>
  ) : null;
}

export default LogoContainer;
