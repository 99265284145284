import { Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Canvas from "./containers/canvas";
import IntroFlow from "./containers/intro";
import InstructionModal from "./components/InstructionModal";
import "./antd.less";
import "./App.css";
import { theme } from "./theme";
import PasswordReset from "./containers/password-reset";
import ResearchModal from "./containers/research";

function App() {
  return (
    <ThemeProvider theme={theme.main}>
      <Routes>
        <Route path="/" element={<IntroFlow />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/canvas/:id/:group" element={<Canvas />} />
        <Route path="/canvas/:id" element={<Canvas />} />
      </Routes>
      <InstructionModal />
      <ResearchModal />
    </ThemeProvider>
  );
}

export default App;
