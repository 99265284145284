import { PlusOutlined, EditOutlined } from "@ant-design/icons/lib/icons";
import { Divider, Input, Popover, Select, Space, Typography } from "antd";
import { StyledText } from "../Text/styled";
import React, { useMemo, useState } from "react";
import { StyledEditIcon, StyledKeyTag } from "./styled";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getRelatedTable } from "../../containers/canvas/selector";

const { Option } = Select;

function KeywordTag({ config, values = {}, row, onChange, prefixTable }) {
  const { keywords: initialkeywords = [], relationTable } = config || {};

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [keywords, setKeywords] = useState(initialkeywords);
  const [nameRelation, setNameRelation] = useState("");

  const hasRelation = !!relationTable?.table;

  const relatedKeywords = useSelector(
    getRelatedTable(relationTable?.part, relationTable?.table)
  );

  const excludedKeywords = useMemo(
    () => Object.values(values).map((k) => k.keyword),
    [values]
  );
  const valueCell = get(values, [row], {});
  const value = get(valueCell, ["keyword"]);

  const [name, setName] = useState(value);

  const keywordsShow = useMemo(
    () =>
      keywords
        .filter((k) => k === value || !excludedKeywords.includes(k))
        .filter(Boolean),
    [excludedKeywords, keywords, value]
  );

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const onNameRelationChange = (value) => {
    setNameRelation(value);
  };

  const onChangeValue = (val) => {
    onChange({
      ...values,
      [row]: {
        ...valueCell,
        keyword: val,
      },
    });
  };

  // TODO add useCallback
  const addItem = (e) => {
    e.preventDefault();

    const keyword = nameRelation !== "" ? `${nameRelation} / ${name}` : name;
    const [, , , part, id] = prefixTable.split(".");

    setKeywords([...keywords, keyword]);
    onChangeValue(keyword);
    dispatch({ type: "SAVE_KEYWORDSTAG", payload: { keyword, part, id } });
    setVisible(false);
  };

  const removeItem = (e) => {
    e.preventDefault();

    const keyword = nameRelation !== "" ? `${nameRelation} / ${name}` : name;

    setKeywords([...keywords].filter(r => r !== keyword));
    onChangeValue(null);
    setVisible(false);
    setName("");
    setNameRelation("");
  };

  const setVisibleHandler = (state) => {
    setVisible(state);
    if (!state) {
      setName("");
      setNameRelation("");
    }
  };

  return (
    <Popover
      visible={visible}
      onVisibleChange={setVisibleHandler}
      trigger="click"
      content={
        <>
          {hasRelation && (
            <>
              <Space align="center">
                <Select
                  placeholder="Voci di relazione"
                  style={{ width: 300 }}
                  value={nameRelation || undefined}
                  onChange={onNameRelationChange}
                >
                  <Option value={""}></Option>
                  {relatedKeywords.map((val, i) => (
                    <Option key={i} value={val}>
                      {val}
                    </Option>
                  ))}
                </Select>
              </Space>
              <Divider style={{ margin: "8px 0" }} />
            </>
          )}
          <StyledKeyTag
            style={{ width: 300 }}
            onChange={setName}
            value={name || undefined}
            placeholder="Seleziona una categoria"
            showSearch
            optionFilterProp="value"
            filterOption={(input, option) => {
              return (
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {keywordsShow.map((item, i) => (
              <Option key={item}>{item}</Option>
            ))}
          </StyledKeyTag>
          <Divider style={{ margin: "8px 0" }} />
          <Space align="center">
            <Input
              style={{ width: 300 }}
              placeholder="Modifica categoria o inserisci nuova"
              value={name}
              onChange={onNameChange}
            />
          </Space>

          <Divider style={{ margin: "8px 0" }} />

          <Space align="center" style={{ padding: "0 8px 4px" }}>
            <Typography.Link
              disabled={!name}
              onClick={addItem}
              style={{ whiteSpace: "nowrap" }}
            >
              <span style={{ color: name ? "#970a2a" : "#ccc" }}>Conferma</span>
            </Typography.Link>
            <Typography.Link
              disabled={!name}
              onClick={removeItem}
              style={{ whiteSpace: "nowrap" }}
            >
              <span style={{ color: name ? "#970a2a" : "#ccc" }}>Elimina</span>
            </Typography.Link>
          </Space>
        </>
      }
    >
      <StyledEditIcon>
        <StyledText
          fontSize="small"
          style={{ padding: "0", lineHeight: "100%" }}
        >
          {value ? (
            <span>{value}</span>
          ) : (
            <div>
              <StyledText
                fontSize="small"
                $variant="danger"
                style={{ padding: 0 }}
              >
                Inserisci
              </StyledText>
              <PlusOutlined style={{ padding: "10px 0 0px" }} />
            </div>
          )}
        </StyledText>
        {value ? <EditOutlined style={{ padding: "10px 0 0px" }} /> : null}
      </StyledEditIcon>
    </Popover>
  );
}

export default KeywordTag;
