import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledButton } from "../Button/styled";
import { StyledText } from "../Text/styled";
import { actions } from "../../containers/app/reducer";
import { getCurrentCanvas } from "../../containers/canvas/selector";
import { actions as canvasActions } from "../../containers/canvas/reducer";

export default function OpenInstructions({ ...props }) {
  const dispatch = useDispatch();
  const currentCanvas = useSelector(getCurrentCanvas);

  useEffect(() => {
    dispatch(
      canvasActions.setPart(
        props.childId.indexOf("part1") > -1 ? "part1" : "part2",
      ),
    );
  }, [props.childId]);

  const titleInstructions = props.isPart1
    ? currentCanvas?.instructions?.titlepart1
    : currentCanvas?.instructions?.titlepart2;

  const open = useCallback(() => {
    dispatch(actions.toggleModal());
  }, [dispatch]);

  return (
    <div style={{ textAlign: "center" }}>
      <StyledButton
        onClick={open}
        style={{ fontSize: 16, maxWidth: "15rem", height: "3rem" }}
      >
        {titleInstructions}
      </StyledButton>
    </div>
  );
}
