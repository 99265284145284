import * as Realm from "realm-web";
import React from "react";
import Header from "../../components/Header";
import Flow from "@stackhouseos/flower-client";
import flow from "../../containers/auth/resetpassword.flower.json"


function PasswordReset() {
  return (
    <>
      <Header />
      <Flow name={flow.name} elements={flow.elements} reducerName="auth" />
    </>
  );
}

export default PasswordReset;