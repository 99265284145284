import React from "react";
import Flow from "@stackhouseos/flower-client";
import flow from "./intro.flower.json";
import Header from "../../components/Header";

function IntroFlow() {
  return (
    <>
      <Header />
      <Flow name={flow.name} elements={flow.elements} reducerName="canvas" />
    </>
  );
}

export default IntroFlow;
