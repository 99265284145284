import styled from "styled-components";
import { Spin } from "antd";

export const StyledLoader = styled(Spin)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  opacity: 0.8;

  .ant-spin-dot-item {
    background-color: ${(props) => props.theme.main};
  }
`;
