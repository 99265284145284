import { CloseOutlined } from "@ant-design/icons/lib/icons";
import styled from "styled-components";

export const StyledModalContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.65);
  justify-content: center;
  overflow-x: hidden;
  z-index: 99;
`;

export const StyledModal = styled.div`
  position: relative;
  height: 70vh;
  max-width: 75vw;
  min-width: 10vw;
  padding: 2rem 5rem;

  @media (max-width: 900px) {
    max-width: 100vw;
    min-width: 50vw;
    padding: 1rem 1rem;
  }
`;

export const StyledCloseIcon = styled(CloseOutlined)`
  position: fixed;
  right: 40px;
  top: 20px;
  cursor: pointer;
  border: "2px solid";

  }
`;
