import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const RedirectUrl = ({ route }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${route}`);
  }, [navigate, route]);

  return null;
};

export default RedirectUrl;
