import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Loader from "../Loader";

export default function SendCanvas({ reducerName, flowName }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "SAVE_CANVASDATA", payload: { reducerName, flowName } });
  }, [dispatch, reducerName, flowName]);

  return <Loader />;
}
