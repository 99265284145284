import React from "react";
import { useSelector } from "react-redux";
import { getCategoryDescription } from "../../containers/canvas/selector";
import { StyledCategoryDescription } from "./styled";
import parse from "html-react-parser";



function CategoryDescription({ value }) {
  const categoriesDescriptionMap = useSelector(getCategoryDescription(value));
  return categoriesDescriptionMap && <StyledCategoryDescription>{parse(categoriesDescriptionMap)}</StyledCategoryDescription>;
}

export default CategoryDescription;
