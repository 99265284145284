import { Switch } from "antd";
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import KeywordTag from "../KeywordTag";
import Select from "../Select";
import { StyledText } from "../Text/styled";
import { StyledCell } from "./styled";


const SwitchStatus = ({ row, values, onChange }) => {

  const valueCell = get(values, [row], {});
  const value = get(valueCell, ['isReversed'], false);
  const isDisabled = !get(valueCell, ['keyword'])

  const onChangeValue = useCallback((val) => {
    onChange({
      ...values,
      [row]: {
        ...valueCell,
        isReversed: val,
      }
    });
  }, [onChange, values, row, valueCell]);

  return <Switch disabled={isDisabled} checkedChildren="Negativo" unCheckedChildren="Positivo" checked={value} onChange={onChangeValue} />

}

const ReplacedDotsWithYears = ({ moment, value }) => {
  return moment.replace(/#|_/g, '').replace('  ', ' ' + value + ' ')
}

const SelectYear = ({ values, onChange, row, moment }) => {
  const valueCell = get(values, [row], {});
  const value = get(valueCell, [moment]);

  // initial values
  useEffect(() => {
    if (!value) {
      onChange({
        ...values,
        [row]: {
          ...valueCell,
          [moment]: 1,
        }
      });
    }
  }, [onChange, moment, values, row, valueCell, value])

  const onChangeValue = useCallback((val) => {
    onChange({
      ...values,
      [row]: {
        ...valueCell,
        [moment]: val
      }
    }
    );

  }, [onChange, values, moment]);

  const years = Array.from(Array(10).keys())

  return (
    <>
      <ReplacedDotsWithYears moment={moment} value={value} />
      <select name="years" value={value} onChange={(e) => onChangeValue(e.target.value)}>
        {years.map((year) => <option value={year + 1} key={year}>
          {year + 1}
        </option>)}
      </select>
    </>
  )
}


const renderMap = {
  text: ({ value }) => (
    <StyledText fontSize="small" $variant="black" fontWeight="bold">
      {value}
    </StyledText>
  ),
  autoComplete: ({ config, results, row, onChange, moment, prefixTable }) => (
    <KeywordTag
      config={config}
      values={results}
      onChange={onChange}
      row={row}
      moment={moment}
      prefixTable={prefixTable}
    />
  ),
  number: ({ config, results, row, onChange, moment }) => (
    <Select
      config={config}
      values={results}
      row={row}
      onChange={onChange}
      moment={moment}
    />
  ),
  selectStatus: ({ config, results, row, onChange, moment }) => (
    <SwitchStatus
      config={config}
      values={results}
      row={row}
      onChange={onChange}
      moment={moment}
    />
  ),
  selectYear: ({ config, results, onChange, moment, row }) => (
    <StyledText fontSize="small" $variant="black" fontWeight="bold">
      <SelectYear
        config={config}
        values={results}
        row={row}
        onChange={onChange}
        moment={moment}
      />
    </StyledText>
  ),
  empty: () => <div>-</div>,
};

export default React.memo(function TableCell({
  value,
  row,
  col,
  colIdx,
  values,
  onChange,
  config,
  prefixTable
}) {
  const render =
    typeof value === "string"
      ? renderMap.text({ value })
      : renderMap[value.type]?.({
        moment: config.rows[colIdx - 1],
        value,
        config,
        results: values,
        row,
        col,
        onChange,
        prefixTable
      }) ||
      renderMap.empty();

  return <StyledCell colIdx={colIdx}>{render}</StyledCell>;
});
