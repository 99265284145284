import { get, isEmpty } from "lodash";
import React, { useLayoutEffect } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getCurrentCanvasCategories } from "../../containers/canvas/selector";
import Divider from "../Divider";
import {
  StyledSummaryRow,
  StyledSummarySpan,
  ChartContainer,
  Container,
  StyledNoData,
  StyledNoDataHeading,
  StyledNoDataText,
  StyledSummaryChart,
} from "./styled";
import _omit from "lodash/omit";
import _first from "lodash/first";
import _last from "lodash/last";
import _get from "lodash/get";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

function BarChart({ chartId, chartTitle, data = [] }) {
  useLayoutEffect(() => {
    const chart = am4core.create(chartId, am4charts.XYChart);

    chart.responsive.enabled = true;
    chart.data = data;
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    // Configure axis label
    const label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.wrap = true;
    label.maxWidth = 120;
    label.tooltipText = "{category}";

    const title = chart.titles.create();
    title.text = chartTitle;
    title.fontSize = 25;
    title.marginBottom = 30;
    categoryAxis.events.on("sizechanged", function (ev) {
      const axis = ev.target;
      const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
      //Consider to use this for responsive
      //axis.renderer.labels.template.maxWidth = cellWidth;

      if (cellWidth < axis.renderer.labels.template.maxWidth) {
        axis.renderer.labels.template.rotation = -45;
        axis.renderer.labels.template.horizontalCenter = "right";
        axis.renderer.labels.template.verticalCenter = "middle";
      } else {
        axis.renderer.labels.template.rotation = 0;
        axis.renderer.labels.template.horizontalCenter = "middle";
        axis.renderer.labels.template.verticalCenter = "top";
      }
    });
    am4core.addLicense("ch-custom-attribution");

    // Create series
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "name";
    series.columns.template.tooltipText =
      "Categoria: {categoryX}\nValore: {valueY}";
    series.columns.template.fill = am4core.color("#F27329");

    return () => {
      chart.dispose();
    };
  }, [chartId, chartTitle, data]);

  return <StyledSummaryChart id={chartId}></StyledSummaryChart>;
}

function difference(a, b) {
  return b - a;
}

function SummaryReport({ config = {}, value = [], part }) {
  const categoriesMap = useSelector(getCurrentCanvasCategories(part));

  const results = useMemo(() => {
    const result = Object.entries(value).reduce((acc, [tableId, inc]) => {
      const catId = config[tableId].category;
      const configCategory = categoriesMap[catId];

      return {
        ...acc,
        [catId]: {
          category: catId,
          description: _get(configCategory, "summary.description", ""),
          title: _get(configCategory, "summary.title", ""),
          puntiDiForza: [
            ...get(acc, [catId, "puntiDiForza"], []),
            ...Object.values(inc.table)
              .filter((t) => {
                if (!(configCategory.summary && configCategory.summary.enable))
                  return false;

                const newObj = _omit(t, ["keyword", 'isReversed']);

                //const { isReversed } = t
                const { strengths: value } = configCategory.summary;
                const isValid = Object.values(newObj).every(
                  (item) => item >= value // && !isReversed
                );

                if (isValid) {
                  return true;
                } else {
                  return false;
                }
              })
              .filter((r) => Object.values(r).length > 1)
              .map((r) => {
                const newObj = _omit(r, ["keyword"]);
                const valueObj = Object.values(newObj).map((i) => i);
                const max = Math.max(...valueObj);
                return {
                  name: r.keyword,
                  value: max,
                };
              }),
          ],
          puntiDiSviluppo: [
            ...get(acc, [catId, "puntiDiSviluppo"], []),
            ...Object.values(inc.table)
              .filter((t) => {
                if (!(configCategory.summary && configCategory.summary.enable))
                  return false;

                const rows = config[tableId].config.rows;
                const newObj = _omit(t, ["keyword"]);
                const differencies = difference(
                  newObj[_first(rows)],
                  newObj[_last(rows)]
                );

                const { devPoints: value } = configCategory.summary;
                const isValid = differencies > 0 && differencies >= value;

                if (isValid) {
                  return true;
                } else {
                  return false;
                }
              })
              .filter((r) => Object.values(r).length > 1)
              .map((r) => {
                const newObj = _omit(r, ["keyword"]);
                const valueObj = Object.values(newObj).map((i) => i);
                const max = Math.max(...valueObj);
                return {
                  name: r.keyword,
                  value: max,
                };
              }),
          ],
        },
      };
    }, {});

    return result;
  }, [config, value, categoriesMap]);

  return (
    <Container>
      {Object.entries(results).map(([category, values], i) => {
        return (
          <ChartContainer key={i}>
            <Divider
              fontSize="medium"
              fontWeight="bold"
              $paddingSpace="big"
              testo={values.title}
              key={category.name}
            />
            <StyledSummarySpan key={category.description}>
              {values.description}
            </StyledSummarySpan>
            <StyledSummaryRow>
              {values.puntiDiForza.length > 0 ? (
                <BarChart
                  chartId={category + "pf"}
                  chartTitle="Punti di forza"
                  data={values.puntiDiForza}
                />
              ) : (
                <StyledNoData>
                  <StyledNoDataHeading>Punti di forza</StyledNoDataHeading>
                  <StyledNoDataText>nessun dato disponibile</StyledNoDataText>
                </StyledNoData>
              )}
              {values.puntiDiSviluppo.length > 0 ? (
                <BarChart
                  chartId={category + "ps"}
                  chartTitle="Punti di sviluppo"
                  data={values.puntiDiSviluppo}
                />
              ) : (
                <StyledNoData>
                  <StyledNoDataHeading>Punti di sviluppo</StyledNoDataHeading>
                  <StyledNoDataText>nessun dato disponibile</StyledNoDataText>
                </StyledNoData>
              )}
            </StyledSummaryRow>
          </ChartContainer>
        );
      })}
    </Container>
  );
}

export default SummaryReport;
