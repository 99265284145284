import styled from "styled-components";
import { Input } from "antd";

export const StyledInput = styled(Input)`
  border: 1px solid ${(props) => props.theme.main};
  border-radius: 10px;
  margin: 1rem 0;
  width: 75%;

  &:hover {
    border: 1px solid ${(props) => props.theme.mainDark};
    transform: none;
  }

  &:focus {
    border-color: ${(props) => props.theme.main};
    border: 1px solid ${(props) => props.theme.main};
    box-shadow: 0 0 0 2px rgb(48 2 14 / 20%);
  }
`;
