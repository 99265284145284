import React from "react";
import { StyledText } from "./styled";
import parse from "html-react-parser";

function Text({
  text,
  value,
  variant,
  fontWeight,
  alignText,
  fontSize,
  paddingSpace,
  isHtml,
}) {
  if (!text && !value) return null;
  const textValue = isHtml ? parse(text || value) : text || value;
  return (
    <StyledText
      $variant={variant}
      fontWeight={fontWeight}
      align={alignText}
      fontSize={fontSize}
      $paddingSpace={paddingSpace}
    >
      {textValue}
    </StyledText>
  );
}

export default Text;
