import React from "react";
import TableRow from "./row";
import { StyledTable } from "./styled";

const TableMemo = React.memo(function Table({
  initialData,
  onChange,
  values,
  config,
  prefixTable
}) {

  return (
    <StyledTable>
      {initialData.map((item, i) => (
        <TableRow
          key={i}
          value={item}
          row={i}
          onChange={onChange}
          values={values}
          config={config}
          prefixTable={prefixTable}
        />
      ))}
    </StyledTable>
  );
});

export default function Example({ initialData, onChange, values, config, prefixTable }) {
  return (
    <div>
      <TableMemo
        initialData={initialData}
        onChange={onChange}
        values={values}
        config={config}
        prefixTable={prefixTable}

      />
    </div>
  );
}
