import React from "react";
import TableCell from "./cell";
import { StyledColumn } from "./styled";

export default React.memo(function TableRow({
  value,
  row,
  onChange,
  values,
  config,
  prefixTable
}) {
  return (
    <StyledColumn>
      {value.map((item, i) => (
        <TableCell
          key={`col-${i}`}
          value={item}
          row={row}
          colIdx={i}
          onChange={onChange}
          values={values}
          config={config}
          prefixTable={prefixTable}
        />
      ))}
    </StyledColumn>
  );
});
