import React from "react";
import { StyledImage, StyledImageContainer } from "./styled";

function Image({ text }) {
  return text ? (
    <StyledImageContainer>
      <StyledImage src={text} alt="Immagine" />
    </StyledImageContainer>
  ) : null;
}

export default Image;
