import { keyBy } from "lodash";
import get from "lodash/get";
import { createSelector } from "reselect";

const selectCanvasSlice = (state) => state.canvas || {};
const selectFlowerSlice = (state) => state.flower || {};

export const getCurrentCanvas = createSelector(
  selectCanvasSlice,
  (canvas) => canvas.current
);

export const getHistory = createSelector(
  selectFlowerSlice,
  (flower) => flower?.canvas?.history
);

export const getRelatedTable = (part, tableId) =>
  createSelector(
    selectCanvasSlice,
    (canvas) => {
      const table = get(canvas, ['data', 'steps', part, tableId, 'table'], {})
      return Object.values(table).map(({ keyword }) => keyword)
    }
  );

export const getCurrentCategories = createSelector(
  getCurrentCanvas,
  (canvas) => canvas.categories
);

export const getCurrentCanvasCategories = (part) =>
  createSelector(getCurrentCanvas, (canvas) =>
    keyBy(canvas.categories[part], "_id")
  );

export const getCategoryName = (id) =>
  createSelector(getCurrentCategories, (categories) => {
    const allCategories = { ...categories.part1, ...categories.part2 };
    return get(allCategories, [id, "name"]);
  });

export const getCategoryDescription = (id) =>
  createSelector(getCurrentCategories, (categories) => {
    const allCategories = { ...categories.part1, ...categories.part2 };
    const summary = get(allCategories, [id, "summary"])
    return summary && summary.description;
  });


export const getTableData = createSelector(
  selectCanvasSlice,
  (canvas) => canvas.data || {}
);