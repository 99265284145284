import React from "react";
import Flow from "@stackhouseos/flower-client";
import flow from "./instructions.flower.json";

function IntroFlow() {
  return (
    <Flow name={flow.name} elements={flow.elements} reducerName="instructions" destroyOnUnmount />
  );
}

export default IntroFlow;
