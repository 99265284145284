import styled from "styled-components";

export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
  border-left: 1px solid black;
  flex: 1;
  width: 100%;

  @media (max-width: 500px) {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 90vw;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const StyledCell = styled.div`
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  min-height: 110px;
  max-height: 110px;
  min-width: ${(props) => (props.colIdx === 0 ? "14rem" : "8rem")};
  max-width: ${(props) => (props.colIdx === 0 ? "14rem" : "8rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex: 1;

  @media (max-width: 500px) {
    padding: ${(props) => (props.colIdx === 0 ? "0.5rem" : "1rem")};
    min-width: ${(props) => (props.colIdx === 0 ? "10rem" : "7rem")};
    max-width: ${(props) => (props.colIdx === 0 ? "10rem" : "7rem")};
  }
`;
