import styled from "styled-components";
import { Input } from "antd";
import styledMap from "styled-map";

const { TextArea } = Input;

const TextColor = styledMap("$variant", {
  primary: "#970a2a",
  secondary: "black",
  white: "white",
});

const FontWeight = styledMap("fontWeight", {
  default: "normal",
  bold: "bold",
});

const Border = styledMap("$variant", {
  small: "1px",
  medium: "1px solid",
  large: "2px solid",
});

export const StyledTextArea = styled(TextArea)`
  color: ${TextColor};
  font-weight: ${FontWeight}
  border: ${Border};
  border-radius: 10px;
  margin-top: 1.5rem;
  
  &:hover {
    border: 1px solid ${TextColor};
    transform: none;
  }
  
  &:focus {
    border-color: ${(props) => props.theme.main};
    border: 1px solid ${(props) => props.theme.main};
    box-shadow: 0 0 0 2px rgb(48 2 14 / 20%)
    
  }
`;
