import React from "react";
import { StyledInput } from "./styled";

export default function MyInput({
  placeholder,
  value,
  onChange,
  rows,
  onBlur,
  typeOf,
}) {
  return (
    <StyledInput
      rows={rows}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      type={typeOf}
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
}
