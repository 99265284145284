import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons/lib/icons";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import Flow from "@stackhouseos/flower-client";
import { StyledButton } from "./styled";

const icons = {
  arrowRight: <ArrowRightOutlined />,
  arrowLeft: <ArrowLeftOutlined />,
};

export default function Button({
  title,
  reducerName,
  flowName,
  variant,
  icon,
  paddingSpace,
  actionType = "flower/next",
  disabled,
  node,
}) {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch({
      type: actionType,
      payload: { reducerName, flowName, node },
    });
  }, [dispatch, reducerName, flowName, actionType, node]);

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      $variant={variant}
      icon={icons[icon]}
      $paddingSpace={paddingSpace}
    >
      {title}
    </StyledButton>
  );
}
