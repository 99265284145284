import styled from "styled-components";

export const StyledSummaryReport = styled.div`
  padding: 0 4rem;
  margin-top: 1rem;
`;

export const StyledSummaryContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
`;

export const StyledSummaryMainContainer = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  margin-top: 1rem 0;
`;

export const StyledSummaryRow = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 700;
  padding-top: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    alig-items: center;
    width: 100%;
  }
`;

export const StyledSummaryKeywords = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

export const ChartContainer = styled.div`
  :not(:first-child) {
    margin-top: 3rem;
    justify-content: center;
  }
`;

export const Container = styled.div`
  margin-bottom: 3rem;
`;

export const StyledSummarySpan = styled.p`
  font-weight: 400;
  font-size: 1rem;
  padding: 1rem 2rem;
  margin: 0;
  display: flex;
  justify-content: center;
`;

export const StyledSummaryChart = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 1rem 0;
  }
`;

export const StyledNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0.3px solid;
  padding: 1rem;
  margin: 2rem;
  width: 50%;

  @media (max-width: 900px) {
    width: 90%;
    margin: 2rem;
  }
`;

export const StyledNoDataHeading = styled.h1`
  font-weight: 700;
  font-size: 25px;
`;

export const StyledNoDataText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1rem;
`;
