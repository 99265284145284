import { groupBy } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { LazyLoad } from "react-observer-api";
import CategoryName from "../CategoryName";
import CategoryDescription from "../CategoryDescription";
import { StyledCanvasStep } from "./styled";
import { StyledLink } from "../Header/styled";
import { actions } from "../../containers/app/reducer";
import { useDispatch } from "react-redux";
import { StyledButton } from "../Button/styled";

const replacePrefixForData =
  ({ required }) =>
    (childId, prefixChildrenId, id) => {
      if (id && id.indexOf("__data.") > -1) {
        return {
          prefix: `${childId.replace(".current.", ".data.")}`,
          prefixChildrenId,
          id: id.replace("__data.", ""),
          required,
        };
      }
      return {
        prefix: childId,
        prefixChildrenId,
        id,
      };
    };

const stylesP = { marginBottom: 50 }

const Item = React.memo(({ prefix, model, renderChildren }) => {

  return (
    <div style={stylesP}>
      {renderChildren(model, prefix, replacePrefixForData(model))}
    </div>
  )
});

const CanvasItem = React.memo(
  ({ category, i, values, childId, renderChildren, types }) => {
    const style = useMemo(() => {
      return i % 2 === 0
        ? { backgroundColor: "#FFFFFF" }
        : { backgroundColor: "#EEEEEE70" }
    }, [i])

    const cleanValues = useMemo(() => values.filter(Boolean), [values])

    return (
      <StyledCanvasStep
        style={style}
      >
        <div id={`section_${category}`}>
          {category !== "undefined" ? (
            <>
              <CategoryName value={category} />
              <CategoryDescription value={category} />
            </>
          ) : null}

          {cleanValues.map((rec) => (
            <Item
              key={rec._id}
              prefix={`${childId}.${rec._id}`}
              renderChildren={renderChildren}
              model={types[rec.type]}
            />
          ))}
        </div>
      </StyledCanvasStep>
    );
  }
);

function CanvasSteps({
  value = {},
  renderChildren,
  itemTable_container_children,
  itemPage_container_children,
  itemPitch_container_children,
  childId,
}) {
  const types = useMemo(() => ({
    table: itemTable_container_children,
    text: itemPage_container_children,
    instructions: [
      {
        type: 'OpenInstructions',
        title: 'instructions',
        isPart1: childId.indexOf('part1') > -1,
        isPart2: childId.indexOf('part2') > -1,
        titlePart: childId.title
      }
    ],
    pitch: itemPitch_container_children,
  }), [itemTable_container_children, itemPage_container_children, itemPitch_container_children])


  const valueByCategories = useMemo(() => groupBy(Object.values(value), "category"), [value])

  return (
    <div>
      {Object.entries(valueByCategories).map(([category, values], i) => {
        return (
          <CanvasItem
            key={category}
            category={category}
            i={i}
            values={values}
            childId={childId}
            renderChildren={renderChildren}
            types={types}
          />
        );
      })}
    </div>
  );
}

export default React.memo(CanvasSteps)