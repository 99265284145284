import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";
import keyBy from "lodash/keyBy";

const initialState = {
  current: null,
  data: {},
};
const addOrder = (items) => {
  return items.map((rec, index) => ({ ...rec, orderIndex: index }))
}




export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setCanvas: (state, { payload }) => {
      const instructions1 = payload.steps.part1.filter((step) => (step.type === 'instructions'));
      const instructions2 = payload.steps.part2.filter((step) => (step.type === 'instructions'));

      const instructionsData1 = keyBy(addOrder(instructions1.map((rec) => ({
        ...rec,
        type: 'text',
      }))), `_id`)
      const instructionsData2 = keyBy(addOrder(instructions2.map((rec) => ({
        ...rec,
        type: 'text',
      }))), `_id`)

      state.current = {
        ...payload,
        steps: {
          intro: keyBy(addOrder(payload.steps.intro), `_id`),
          part1: keyBy(addOrder(payload.steps.part1), `_id`),
          part2: keyBy(addOrder(payload.steps.part2), `_id`),
          pitch1: keyBy(addOrder(payload.steps.pitch1), `_id`),
          pitch2: keyBy(addOrder(payload.steps.pitch2), `_id`),
        },
        categories: {
          part1: keyBy(payload.categories.part1, `_id`),
          part2: keyBy(payload.categories.part2, `_id`),
        },
        titles: {
          intro: payload.titles.intro,
          part1: payload.titles.parte1,
          part2: payload.titles.parte2,
          pitch1: payload.titles.pitch1,
          pitch2: payload.titles.pitch2,
        }
      };

      set(state, "data.id", payload.id);
      set(state, "data.slug", payload.slug);
      set(state, "current.instructions.part1", instructionsData1);
      set(state, "current.instructions.part2", instructionsData2);
      set(state, "current.instructions.titlepart1", instructions1[0]?.title.replace(/(<([^>]+)>)/g, "") || "");
      set(state, "current.instructions.titlepart2", instructions2[0]?.title.replace(/(<([^>]+)>)/g, "") || "");
    },
    setPart: (state, { payload }) => {
      set(state, "current.currentPart", payload);
    },
    destroy: (state, { payload }) => {
      state.data = {};
    },
  },
  extraReducers: {
    "@flower/canvas/update": (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = canvasSlice.actions;

export default canvasSlice.reducer;
