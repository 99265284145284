import styled from "styled-components";

const StyledPageTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0 0.5rem;
`;

const StyledPageTitleIndex = styled.h2`
  color: ${(props) => props.theme.main};
  border: 1px solid ${(props) => props.theme.main};
  text-align: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin-right: 1rem;
`;

const StyledPageTitleText = styled.h1`
  flex:1;
  font-weight: 700;
  color: #30020e;
  margin-bottom: 0;
`;

export { StyledPageTitle, StyledPageTitleIndex, StyledPageTitleText };
