import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FlowerContext } from "@stackhouseos/flower-client";
import Loader from "../Loader";

const Saga = React.memo(({ name, value, loaderColor }) => {
  const dispatch = useDispatch();
  const { flowName, reducerName } = useContext(FlowerContext);

  useEffect(() => {
    if (name)
      dispatch({
        type: name,
        payload: {
          flowName,
          reducerName,
          value,
          outputFlow: "saga",
        },
      });
  }, [dispatch, name, flowName, reducerName, value]);

  return <Loader />;
});

export default Saga;
