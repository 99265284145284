import styled from "styled-components";
import styledMap from "styled-map";

const SidebarMaxWidth = styledMap("sizeMaxWidth", {
  small: "15%",
  medium: "20%",
  big: "40%",
});

const SidebarMinWidth = styledMap("sizeMinWidth", {
  small: "20%",
  medium: "25%",
  big: "37%",
});

const StyledSidebar = styled.div`
  display: flex;
  position: sticky;
  min-width: ${SidebarMinWidth};
  max-width: ${SidebarMaxWidth};
  top: 0;
  padding: 5rem 2rem 0 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 1;
  overflow-x: hidden;
  background-color
    ${(props) => props.theme.main}
  ;
  object-fit: cover;
  color: ${(props) => props.theme.white};

  @media (max-width: 900px) {
    position: relative;
    flex: 1;
    min-width: 100%;
    max-width: 100%;
    min-height: 25vh;
    align-items: bottom;
    padding: 3rem 2rem 0 2rem;
  }
`;

const StyledSidebarContainer = styled.div`
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  text-align: center;
  color: ${(props) => props.theme.main};
  z-index: 2;
`;

const StyledSidebarTitle = styled.h1`
  color: #fdfdfd;
  text-transform: uppercase;
  padding: 0.5rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  word-break: break-word;

  @media (max-width: 1420px) {
    padding: 0 3rem;
    word-break: break-word;
    font-size: 1.5rem;
  }
`;

const StyledSidebarSubTitle = styled.h2`
  color: #fdfdfd;
  padding: 0 3rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
`;

export {
  StyledSidebar,
  StyledSidebarContainer,
  StyledSidebarTitle,
  StyledSidebarSubTitle,
};
