import styled from "styled-components";
import styledMap from "styled-map";
import { Typography } from "antd";

const TextColor = styledMap("$variant", {
  title: "#FDFDFD",
  primary: "#30020E",
  secondary: "#FDFDFD",
  textGrey: "#555555",
  danger: "#970A2A",
  black: "#30020e",
});

const FontWeight = styledMap("fontWeight", {
  default: "normal",
  bold: "700",
});

const FontSize = styledMap("fontSize", {
  small: "14px",
  medium: "18px",
  big: "36px",
});

const textAlign = styledMap("align", {
  left: "left",
  center: "center",
});

const Padding = styledMap("$paddingSpace", {
  none: "0px",
  small: "0.5rem",
  big: "1rem",
});

export const StyledText = styled(Typography)`
  color: ${TextColor};
  font-weight: ${FontWeight};
  font-size: ${FontSize};
  text-align: ${textAlign};
  padding: ${Padding} 0;
`;
