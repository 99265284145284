import { combineReducers } from "@reduxjs/toolkit";
import { flowerReducer } from "@stackhouseos/flower-client";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import AsyncStorage from "@react-native-community/async-storage";
import { persistReducer } from "redux-persist";

import canvas from "../containers/canvas/reducer";
import app from "../containers/app/reducer";
import auth from "../containers/auth/reducer";

const appPersistConfig = {
  key: "app",
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["hideTutorial"],
};

const canvasPersistConfig = {
  key: "canvas",
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["data"],
};

const authPersistConfig = {
  key: "auth",
  storage: AsyncStorage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["isLogged"],
};

export default combineReducers({
  app: persistReducer(appPersistConfig, app),
  canvas: persistReducer(canvasPersistConfig, canvas),
  auth: persistReducer(authPersistConfig, auth),
  flower: flowerReducer,
});
