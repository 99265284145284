import styled from "styled-components";

export const StyledDynamicList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIndex = styled.h3`
  color: ${(props) => props.theme.second};
  border: 1px solid ${(props) => props.theme.second};
  text-align: center;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  width: 30px;
  max-width: 30px;
  min-width: 30px;
  height: 30px;
  margin-bottom: 12px;
  margin-right: 1rem;

  @media (max-width: 900px) {
    display: none;
  }
`;
