import styled from "styled-components";


export const StyledPdfPreview = styled.div`
  background-color: rgba(253, 253, 253, 0.92);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    max-width: 100%;
  }
`;
