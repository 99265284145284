import styled from "styled-components";

export const StyledSidebarCircle = styled.div`
  background-color: ${(props) => props.theme.mainDark};
  border-radius: 100%;
  position: absolute;
  height: 70vh;
  width: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @media (max-width: 1400px) {
    padding: 7rem;
    position: relative;
  }

  @media (max-width: 900px) {
    background-color: ${(props) => props.theme.main};
    margin-bottom: 0;
    padding: 6rem 10rem;
    height: 0;
    border-radius: 0;
    width: 100vh;
  }
`;
