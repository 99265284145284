import styled from "styled-components";

export const StyledImageContainer = styled.div`
  position: relative;
  display: flex;
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 1rem 0;
  justify-content: center;
`;

export const StyledImage = styled.img`
  width: 65%;
  padding: 3rem;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 1rem;
  }

  @media (max-width: 600px) {
    padding: 2rem;
  }
`;
