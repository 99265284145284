import React from "react";
import { StyledSinglePageContent } from "./styled";

function PageContent({ children, gutterHorizontal }) {
  return (
    <StyledSinglePageContent $gutterHorizontal={gutterHorizontal}>
      {children}
    </StyledSinglePageContent>
  );
}

export default PageContent;
