import styled from "styled-components";

const StyledSidebarItem = styled.div`
  display: flex;
  padding: 0.5rem 0;
  background-color: ${(props) => props.theme.main};
  align-items: center;
  }
`;

const StyledSidebarItemIndex = styled.h2`
  color: #fdfdfd;
  border: 1px solid ${(props) => props.theme.second};
  text-align: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;

const StyledSidebarItemText = styled.a`
  color: ${(props) => props.theme.second};
`;

export { StyledSidebarItem, StyledSidebarItemIndex, StyledSidebarItemText };
