import React from "react";
import { StyledSwitchButton, StyledSwitchText } from "./styled";

function SwitchButton({ name, onClick, active }) {
  return (
    <StyledSwitchButton onClick={onClick} active={active}>
      <StyledSwitchText>{name}</StyledSwitchText>
    </StyledSwitchButton>
  );
}

export default SwitchButton;
