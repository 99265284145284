import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

const initialState = {
  email: '',
  password: ''
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    "@flower/auth/update": (state, action) =>
      set(state, action.payload.id, action.payload.value),
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
